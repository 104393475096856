import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import FoundationReportsLayout from "../layouts/FoundationReportsLayout/FoundationReportsLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityCampaign } from "../model/common";
import {
  SanityFoundationReportMeta,
  SanityFoundationReportsPage
} from "../model/impact";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query FoundationReportsPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityFoundationReportsPage(_id: { eq: $_id }) {
      _id
      _type
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      _rawContent(resolveReferences: { maxDepth: 5 })
    }
    allMeta: allSanityFoundationReport(sort: { fields: [type, title] }) {
      nodes {
        _id
        _type
        title
        location {
          ... on SanityCountry {
            name
          }
          ... on SanityRegion {
            name
          }
        }
        type
        slug {
          current
        }
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "impact" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const FoundationReports = ({
  pageContext,
  data: { menus, page, campaigns, allMeta },
  location
}: FoundationReportsProps) => {
  // NB. The page title is the less helpful "Introduction" as it's
  // titled like a section of Foundation reports. Can't use useString
  // here as the context is defined by us.
  const title = pageContext.strings["foundation-reports"];
  return (
    <PageLayout
      menus={menus}
      siteArea={SiteArea.IMPACT}
      metadata={{
        page,
        title,
        alternates: pageContext.alternates,
        type: "article"
      }}
      strings={pageContext.strings}
      location={location}
    >
      <FoundationReportsLayout
        introduction={page}
        article={page}
        allMeta={allMeta.nodes}
        campaigns={campaigns.nodes}
      />
    </PageLayout>
  );
};

export default FoundationReports;

interface FoundationReportsProps {
  data: {
    menus: GlobalMenus;
    page: SanityFoundationReportsPage;
    campaigns: { nodes: SanityCampaign[] };
    allMeta: { nodes: SanityFoundationReportMeta[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
